import {each, load, map, on} from './nw'

const __ = {
    modules: {},
    init: (node, module) => {
        const [handler, events, data] = HANDLER(node, module.qn)
        /**
         * @param {token} data-action-*-on="key.enter:debounce/body"
         */
        ON(node, events, handler)
        node.dispatchEvent(new CustomEvent(`init-${module.fqn}`, {detail: data, bubbles: true}))
        return _ => {
            for (const emit of TOKENIZE(data.emit ?? [])) {
                node.dispatchEvent(new CustomEvent(emit, {detail: data, bubbles: true}))
            }
        }
    },
    prefix: str => (str.includes('data-action-') ? str : `data-action-${str}`),
    value: value => {
        if (typeof(value) === 'string' && ['"', '{', '['].includes(value[0])) {
            try {
                return JSON.parse(value)
            } catch {}
        }
        return value
    },
}

const TOKENIZE = (input, sep = ',') => {
    input = typeof(input) === 'string' ? input.split(sep) : input
    return map(input, token => token.trim())
}

const ON = (node, events, handler) => {
    each(TOKENIZE(events), token => {
        const [rest1, target] = TOKENIZE(token, '/')
        const [rest2, debounce] = TOKENIZE(rest1, ':')
        const [event, key] = TOKENIZE(rest2, '.')
        each(target ?? node, el => {
            on(el, event, e => {
                if (key && key.toUpperCase() !== e.key.toUpperCase()) {
                    return
                }
                handler(e)
            }, debounce)
        })
    })
}

const TEMPLATE = (str, data = undefined) => {
    if (data.constructor === Array) {
        data = Object.assign({}, data)
    }
    data && (str = str.replaceAll('${', "${data['").replaceAll('}', "']}"))
    return eval('`' + str.replaceAll('`', '\\`') + '`')
}

const ATTRS = (el, prefix = 'data') => {
    prefix = prefix.replaceAll('[', '').replaceAll(']', '')
    return {
        value: __.value(el.getAttribute(prefix)),
        data: Object.fromEntries(map(
            el.attributes,
            att => att.name.startsWith(`${prefix}-`) ? [att.name.substring(prefix.length + 1), __.value(att.value)] : null
        ))
    }
}
ATTRS.OBJ = (el, prefix, mixin = null) => {
    const attrs = ATTRS(el, prefix)
    return {...mixin, ...attrs.value, ...attrs.data}
}

const TODO = (name = 'callback') => (...args) => console.error('@TODO implement', name, {args: args})

const KV = (selectors, handle, root = undefined) => map(selectors, map.kv((selector, item) => {
    const all = []
    each(selector, el => all.push([el, ATTRS.OBJ(el, selector, item)]), root)
    map(all, ([el, data]) => handle(el, data, all))
}))

const HANDLER = (el, name) => {
    const {data: data, value: value} = ATTRS(el, __.prefix(name))
    let module = __.modules[name] ?? TODO(name)
    const handler = typeof(value) === 'string' ? (module.fromString ?? TODO(`${name}.fromString`)) : module
    return [
        (...args) => handler(value, el, data, ...args),
        data?.on ?? module?.on ?? 'click',
        data,
    ]
}

const ACTION = (name, module, props = {}) => {
    if (__.modules[name] === undefined) {
        for (const [key, value] of Object.entries(props)) {
            module[key] = value
        }
        __.modules[name] = module
        module.qn = name
        module.fqn = __.prefix(name)
        module.nodes = []

        load.on(root => {
            each(`[${module.fqn}]`, node => {
                if (module.nodes.indexOf(node) < 0 && module.nodes.push(node)) {
                    load.on(__.init(node, module), true)
                }
            }, root)
        })
    }
    return __.modules[name]
}

export {
    TOKENIZE,
    TEMPLATE,
    ATTRS,
    TODO,
    KV,
    HANDLER,
    ACTION,
    ON,
}