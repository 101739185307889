import {each, map, toggle} from './modules/nw'
import {KV, TOKENIZE, ACTION} from './modules/action'

ACTION('toggle', selectors => KV(
    selectors,
    (el, classes) => map(classes, (enable, cl) => toggle(el, cl, enable))
), {
    fromString: (str, el) => each(TOKENIZE(str), token => {
        const [cls, sel] = TOKENIZE(token, ':')
        each(sel ?? [el], el => toggle(el, cls))
    })
})